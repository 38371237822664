import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Button} from "@mui/material";
import {useTheme} from "@mui/material/styles";

interface CardMenuProps {
    stockSymbol: string;
}

export const CardMenu: React.FC<CardMenuProps> = ({stockSymbol}) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openFinvizLink = (stockSymbol: string) => {
        const url = `https://finviz.com/quote.ashx?t=${stockSymbol}&p=d`;
        window.open(url, '_blank')?.focus();
        handleClose();
    }

    return (
        <div>
            <Button
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    borderRadius: "1px",
                    maxHeight: "15px",
                    maxWidth: "32px",
                    minWidth: '0px',
                    height: "15px",
                    paddingTop: "13px",
                    paddingRight: "0px",
                    marginRight: "-8px",
                    color: theme.palette.primary.main
                }}
            >
                <MoreHorizIcon sx={{ marginRight: '10px' }}/>
            </Button>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => openFinvizLink(stockSymbol)}>finviz</MenuItem>
            </Menu>
        </div>
    );
}