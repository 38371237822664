import {LineChart, Line, Tooltip, Cell} from 'recharts';
import {Stock} from "./interfaces";
import {BrandColorScheme} from "./brandColors";
import {Box} from "@mui/material";
import {formatDate, formatPercentage} from "./utils";
import {useTheme} from "@mui/material/styles";

interface StockChartProps {
    stock: Stock;
    brandColors: BrandColorScheme;
}

const CustomTooltip = ({active, payload}: any) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        if (!data.date) {
            console.error('undefined date in payload');
            return null;
        }

        return (
            <Box sx={{display: 'flex', flexDirection: 'column', paddingTop: '15px'}}>
                <Box sx={{fontSize: '8px'}}>{`${formatDate(data.date)}`}</Box>
                <Box sx={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
                    <Box>{`$${data.price.toFixed(2)}`}</Box>
                <Box sx={{fontSize: '8px', paddingTop: '2px', color: data.change > 0 ? "green" : "red"}}>{data.change.toFixed(2)}%</Box>
                </Box>
            </Box>
        );
    }

    return null;
};

const StockChart: React.FC<StockChartProps> = ({stock, brandColors}) => {
    const theme = useTheme();

    const stockHistory = stock.history.map(history => ({
        symbol: history.details.alphaVantage.overview.symbol,
        date: new Date(history.date),
        price: history.details.price,
        isUndervalued: history.details.isUndervalued,
        change: formatPercentage(history.details.alphaVantage.globalQuote.changePercent),
    }));

    const sortedStockHistory = [...stockHistory].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());


    return (
        <LineChart width={120} height={60} data={sortedStockHistory}>
            <Tooltip content={<CustomTooltip/>}/>
            <Line
                key={stock.symbol}
                type="monotone"
                dataKey="price"
                stroke={theme.palette.secondary.main}
                dot={({cx, cy, payload, index}) => {
                    return (
                        <circle
                            key={`dot-${index}`}
                            cx={cx}
                            cy={cy}
                            r={0}
                            fill={payload.isUndervalued ? 'green' : 'red'}
                        />
                    );
                }}
                activeDot={false}
                connectNulls={true}
            >
                {stockHistory.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.isUndervalued ? 'green' : 'red'}/>
                ))}
            </Line>
        </LineChart>
    );
};

export default StockChart;
