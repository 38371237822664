import React, {ReactNode} from "react";
import {Box} from "@mui/material";

interface MetaRowProps {
    title: string;
    value: ReactNode;
}

export const MetaRow: React.FC<MetaRowProps> = ({title, value }) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px'}}>
            <Box sx={{fontWeight: '200'}}>
                {title}
            </Box>
            <Box sx={{fontWeight: '300'}}>
                {value}
            </Box>
        </Box>
    )
}