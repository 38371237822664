export type BrandColorScheme = {
    backgroundColor: string;
    textColor: string;
};

export function getBrandColours(stockSymbol: string): BrandColorScheme {
    switch (stockSymbol) {
        case 'PTON':
            return {
                backgroundColor: '#ca2939',
                textColor: '#f2f2f2',
            };
        case 'BYND':
            return {
                backgroundColor: '#6fb750',
                textColor: '#252022',
            };
        default:
            return {
                backgroundColor: '#B79bff',
                textColor: '#fff',
            };
    }
}
