import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#653c17',
        },
        secondary: {
            main: '#7f4d1e',
        },
        error: {
            main: '#ff0f00',
        },
        warning: {
            main: '#fe5f55',
        },
        info: {
            main: '#47a8bd',
        },
        success: {
            main: '#507d33',
        },
        background: {
            default: '#ffdab9',
        }
    },
    typography: {
        fontFamily: [
            'Nunito Sans',
            'sans-serif',
        ].join(','),
    },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#cbcbcb',
        },
        secondary: {
            main: '#9a9a9a',
        },
        background: {
            default: '#2b2b2b',
        },
        error: {
            main: '#fe2d37',
        },
        warning: {
            main: '#ff6c0d',
        },
        info: {
            main: '#256eff',
        },
        success: {
            main: '#98ff12',
        }
    },
    typography: {
        fontFamily: [
            'Nunito Sans',
            'sans-serif',
        ].join(','),
    },
});
