import {Box} from "@mui/material";
import StockChart from "./StockChart";
import React from "react";
import {Stock} from "./interfaces";
import {getBrandColours} from "./brandColors";
import {CardMenu} from "./CardMenu";
import {MetaRow} from "./MetaRow";
import {formatCurrency} from "./utils";
import { useTheme } from '@mui/material/styles';

interface StockCardProps {
    stock: Stock
}

export const StockCard: React.FC<StockCardProps> = ({stock}) => {
    const theme = useTheme();
    const brandColors = getBrandColours(stock.symbol)
    const isUndervalued = stock.history[0].details.isUndervalued

    const sdp = stock.history[0]
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '100%',
            maxWidth: '390px',
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '5px',
            padding: '16px 12px',
            height: '235px',
            width: '210px',
            fontSize: '12px',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Box>
                            {/* logo */}
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', fontWeight: 700, fontSize: '18px', color: theme.palette.secondary.main}}>
                            {stock.history[0].details.alphaVantage.overview.name}
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{textAlign: 'right', fontWeight: '300', fontSize: '20px'}}>
                            {stock.symbol}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Box>
                        <Box sx={{fontWeight: 200, fontSize: '14px'}}>Current Price</Box>
                        <Box sx={{display: 'flex', displayDirection: 'row', fontSize: '24px'}}>
                            ${stock.history[0].details.price}
                        </Box>
                    </Box>
                    <Box sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                        <StockChart stock={stock} brandColors={brandColors}/>
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <MetaRow
                        title="Target"
                        value={`${formatCurrency(sdp.details.grahamNumber.toString())}`}
                    />
                    <MetaRow
                        title="Target Difference"
                        value={
                            <Box sx={{color: isUndervalued ? theme.palette.success.main : theme.palette.warning.main}}>
                                {isUndervalued ?
                                formatCurrency((sdp.details.grahamNumber-sdp.details.price).toString()) :
                                '-'+formatCurrency((sdp.details.price-sdp.details.grahamNumber).toString())}
                            </Box>
                        }
                    />
                    <MetaRow
                        title="EPS"
                        value={formatCurrency(sdp.details.alphaVantage.overview.eps)}
                    />
                    <MetaRow
                        title="RPS TTM"
                        value={formatCurrency(sdp.details.alphaVantage.overview.revenuePerShareTtm)}
                    />
                    <MetaRow
                        title="Profit Margin"
                        value={formatCurrency(sdp.details.alphaVantage.overview.profitMargin)}
                    />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <MetaRow
                        title="52w high"
                        value={formatCurrency(sdp.details.alphaVantage.overview['52WeekHigh'])}
                    />
                    <MetaRow
                        title="52w low"
                        value={formatCurrency(sdp.details.alphaVantage.overview['52WeekLow'])}
                    />
                    <MetaRow
                        title="50d avg"
                        value={formatCurrency(sdp.details.alphaVantage.overview['50DayMovingAverage'])}
                    />
                    <MetaRow
                        title="PEG ratio"
                        value={sdp.details.alphaVantage.overview.pegRatio === "None" ? '—' : sdp.details.alphaVantage.overview.pegRatio}
                    />
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignContent: 'flex-end'
            }}>
                <CardMenu stockSymbol={stock.symbol}/>
            </Box>
        </Box>
    )
}