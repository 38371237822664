import React, {ReactNode} from "react";
import axios from "axios";
import {StockData} from "./interfaces";
import {camelizeKeys} from 'humps'
import {
    Alert,
    Snackbar,
    CircularProgress,
    Backdrop,
    Box,
} from "@mui/material";
import {useQuery} from "react-query";
import {StockCard} from "./StockCard";
import {useTheme} from "@mui/material/styles";

interface DashboardProps {
    themeSwitch: ReactNode,
}

export const Dashboard: React.FC<DashboardProps> = ({themeSwitch}) => {
    const theme = useTheme();

    const {isLoading, isError, data, error} = useQuery<StockData, Error>(['stocks'], () => fetchStocks());

    const fetchStocks = async (): Promise<StockData> => {
        const response = await axios.get(`https://data.insightum.tech/2023.json`);
        return camelizeKeys(response.data) as StockData;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '20px',
            minHeight: '97vh'
        }}>
            <Box style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '28px',
                fontSize: '28px',
                fontWeight: '400'
            }}>
                <Box>
                    <Box style={{
                        fontSize: '1.22rem',
                        fontWeight: 500,
                        color: theme.palette.primary.main
                    }}>insightum</Box>
                    <Box style={{
                        fontSize: '1.0312rem',
                        fontWeight: 100,
                        marginTop: '-13px',
                        color: theme.palette.secondary.main
                    }}>stock tracker</Box>
                </Box>
                {themeSwitch}
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, gap: '15px'}}>
                {isError &&
                    <Snackbar
                        open={isError}
                        autoHideDuration={6000}
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    >
                        <Alert severity="error" sx={{width: '100%'}}>
                            Failed to fetch stock data. {error?.message}.
                        </Alert>
                    </Snackbar>
                }
                {isLoading ? (
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                ) : (
                    data?.stocks.map(stock => (
                        <StockCard key={stock.symbol} stock={stock}/>
                    ))
                )}
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '10px',
                fontWeight: '100',
                color: theme.palette.secondary.main
            }}>
                © {new Date().getFullYear()} Insightum Technology Company. All rights reserved.
            </Box>
        </Box>
    );
}
