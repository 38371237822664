import {format} from "date-fns";

export const formatCurrency = (value: string): string => {
    const fv = parseFloat(value)
    return fv < 0 ? `-$${Math.abs(fv).toFixed(2)}` : `$${fv.toFixed(2)}`;
};

export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return '';
    }
    return format(date, 'MMMM d, yyyy');
};

export const formatPercentage = (percentageString: string): number => {
    return parseFloat(percentageString.replace('%', ''));
};
